var sliderSpecialApp = {
    INFOS: {
        name: "sliderSpecialApp",
        version: 0.1,
        asserts: ["jquery", "slick"],
    },

    init: function (src_data) {
        this.cacheDom(src_data);
        this.activateSlick();
        this.initRightSizes();
        this.bindEvents();
    },

    cacheDom: function (src_data) {
        this.slider = $(src_data.sliderContainer);
        this.sliderView = $(src_data.sliderView);
        this.destrArea = $(src_data.sliderItemDescription);
        this.sliderHeight = this.slider.height();
        this.sliderSpecialHeight = this.sliderView.height()  / 3 - 90;
        this.items = $(src_data.sliderItems);
        this.image = $(this.items.find(src_data.sliderItems + " > img"));
        this.imgHight = this.image.height();
        this.imgWidth = this.image.width();
        this.description = src_data.description;
        this.style = src_data.style;
    },

    activateSlick: function () {
        // SLICK BEHAVIOR
        this.sliderView.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            accessibility: false,
            cssEase: 'ease',
            infinite: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        });
    },

    initRightSizes: function () {
        // MAIN-SETTINGS
        this.sliderView.css("height", (this.sliderSpecialHeight + "px"));

        // FIRST-IMAGE
        this.items.each(function () {
            var slickIndex = parseInt($(this).attr("data-slick-index"));
            if (slickIndex !== 0) {
                $(this).find("img").css({
                    opacity: '0.35',
                    padding: '35px',
                });
                if(slickIndex !== 1)
                    $(this).css({
                        "visibility" : "hidden",
                        "opacity" : 0,
                    });
                $(this).find(".module-slider-special__image-title").addClass("color-secondary-slider");
                //$(this).append($("<div></div>", {class: "image-gradient-right",}));
            } else {
                $(this).find("img").animate({
                    opacity: '1',
                }, 350, 'swing');
                //$(this).find(".image-title").css("color", sliderSpecialApp.style.title);
                $(this).find(".module-slider-special__image-title").addClass("color-primary-slider");
            }
            $(this).css("width", "300px");
        });

        // DESCRIPTION
        // CALCULATE HEIGHT
        var maxSizeOfDescriptionString = 0, indexOfMax = 0;
        this.description.forEach(function (item, index) {
            if(maxSizeOfDescriptionString < item.length) {
                maxSizeOfDescriptionString = item.length;
                indexOfMax = index;
            }
        });
        this.maxHeightOfDescription = this.destrArea.html(sliderSpecialApp.description[indexOfMax]).height();
        // SET FIRST CONTENT
        this.destrArea.html(sliderSpecialApp.description[0]).fadeIn(350);
        // SET RIGHT HEIGHT OF DESCRIPTION DIV-ELEMENT
        console.log(this.maxHeightOfDescription);
        this.destrArea.css("height", this.maxHeightOfDescription + "px");
    },

    bindEvents: function () {
        // BEVOR SLIDE CHANGE
        this.sliderView.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            // SLIDE AND MANIPULATE IMAGES
            sliderSpecialApp.items.each(function () {
                var currentSlide = parseInt($(this).attr("data-slick-index"));

                var visibleItems = [];
                var visible = false;
                visibleItems.push(nextSlide);
                visibleItems.push(nextSlide - 1);
                visibleItems.push(nextSlide + 1);

                visibleItems.forEach(function(item, index) {
                    if(item === currentSlide) {
                        visible = true;
                    }
                });

                //console.log(!jQuery.inArray(currentSlide, visibleItems));

                if (currentSlide !== nextSlide) {
                    if(!visible) {
                        $(this).css({"visibility" : "hidden", "opacity" : 0,});
                    } else {
                        $(this).css({"visibility" : "visible", "opacity" : 1,}).fadeIn(200);
                    }

                    $(this).find("img").animate({
                        opacity: '0.35',
                        padding: '35px',
                    }, 350, 'swing');
                    $(this).find(".module-slider-special__image-title")
                        .removeClass("color-primary-slider")
                        .addClass("color-secondary-slider");
                    //$(this).find(".module-slider-special__image-title").css("color", sliderSpecialApp.style.content);
                } else {
                    $(this).find("img").animate({
                        opacity: '1',
                        padding: '0px',
                    }, 350, 'swing');
                    $(this).find(".module-slider-special__image-title")
                        .removeClass("color-secondary-slider")
                        .addClass("color-primary-slider");
                    //$(this).find(".module-slider-special__image-title").css("color", sliderSpecialApp.style.title);
                }
            });
        });

        // On before slide change
        this.sliderView.on('afterChange', function (event, slick, currentSlide, nextSlide) {
            sliderSpecialApp.destrArea.fadeOut(function () {
                $(this).text(sliderSpecialApp.description[currentSlide]);
            }).fadeIn(350);
        });
    },
};