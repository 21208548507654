function closeCookies() {
  setCookies(60, "cookies-accented", "Cookies Accepted");
  $('#close-cookies').hide();
}

function setCookies(exdays, cname, cvalue) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  showCookies();
}

function showCookies() {
  var accepted = getCookie("cookies-accented");
  if (accepted !== "") {
    $('#cookies').hide();
  } else {
    $('#cookies').fadeIn();
  }
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function initOwlCarousel() {
  $(document).ready(function () {
    $('#owl-guest').owlCarousel({
      loop: true,
      dots: true,
      items: 1,
      autoplay: 5000,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 2
        },
        1024: {
          items: 3
        },
        1200: {
          items: 4
        },
      }
    });
  });

  $(document).ready(function () {
    $('#owl-sponsors').owlCarousel({
      loop: true,
      dots: true,
      items: 1,
      autoplay: 5000,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 3
        },
        1024: {
          items: 4
        },
        1200: {
          items: 6
        },
      }
    });
  });

}

function sliderAnimation() {
  var objs = {
    img: "slider-scene-img",
    title: "slider-scene-title",
    textLeft: "#scene-text-left",
    textRight: "#scene-text-right",
    devType: getDeviceType(),
  }

  // Fix width of image for iphoneX
  var iphoneX = window.innerHeight > 660 && objs.devType === 1;

  if (iphoneX) {
    console.log("iphoneX")
    $("#" + objs.img).css({
      'top': '5%',
      'height': '60%',
    });
    $("#" + objs.title).css({
      'top': '50%',
      'height': '25%',
    });
  }

  // SCROLL
  $(window).scroll(function () {
    var wScroll = $(this).scrollTop();
    var imgHeight = 80, imgTitle = 40;

    // for mobile change 80 -> 75 and 40 -> 30
    if (document.body.offsetWidth <= 1024) {
      imgHeight = 75
      imgTitle = 30
    }

    if (iphoneX) {
      imgHeight = 60
      imgTitle = 25
    }

    $("#" + objs.img).css({
      'height': imgHeight + wScroll / 100 + '%',
    });

    $("#" + objs.title).css({
      'height': imgTitle + wScroll / 125 + '%',
    });
  });

  // MOUSE MOVE
  var started = false;
  var img = $("#" + objs.img)
  var title = $("#" + objs.title)

  var imgLeftStart, imgTopStart, titleLeftStart, titleTopStart

  var imgNode = document.getElementById(objs.img)
  var titleNode = document.getElementById(objs.title)

  if (objs.devType === 3) {
    $(document).mousemove(function (event) {
      var move_x = event.pageX;
      var move_y = event.pageY;
      if (!started) {
        imgLeftStart = imgNode.offsetLeft;
        imgTopStart = imgNode.offsetTop;
        titleLeftStart = titleNode.offsetLeft;
        titleTopStart = titleNode.offsetTop;
        started = true;
      }

      img.css({
        'left': imgLeftStart + smooth(move_x, 0.25) + "px",
        'top': imgTopStart + smooth(move_y, 0.250) + "px",
      });

      title.css({
        'left': titleLeftStart + smooth(move_x, -0.75) + "px",
        'top': titleTopStart + smooth(move_y, -0.75) + "px",
      });

      function smooth(move, depth) {
        return -Math.round(Math.sqrt(move)) * depth
      }
    });
  }
}

function counterAnimation() {
  var mutex = false;
  var item = '.module-counter__item';

  $(window).scroll(function () {
    var hT = $(item).offset().top,
      hH = $(item).outerHeight(),
      wH = $(window).height(),
      wS = $(this).scrollTop();
    if (wS > (hT - hH - wH)) {
      if (!mutex) {
        mutex = true;
        count();
      }
    }
  });

  function count() {
    var el = $('.module-counter__item-title > p');
    el.each(function (i, item) {
      var start = 0;
      var final = parseInt(item.textContent, 10);
      var duration = 10000;
      var step = function (ts) {
        if (!start) start = ts;
        // get the time passed as a fraction of total duration
        var progress = (ts - start) / duration;
        item.textContent = Math.floor(progress * final);
        // if we're not 100% complete, request another animation frame
        if (progress < 1)
          requestAnimationFrame(step);
        else
          item.textContent = Math.floor(final);
      }
      // start the animation
      requestAnimationFrame(step);
    });
  }
}

function smoothScrollEffect() {
  $('a[href^="#"]').on('click', function (e) {
    e.preventDefault();
    var target = this.hash;
    var $target = $(target);
    $('html, body').stop().animate({
      'scrollTop': $target.offset().top
    }, 900, 'swing', function () {
      window.location.hash = target;
    });
  });
}

function getDeviceType() {
  var ua = navigator.userAgent;
  var smRegEx = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/;
  var tbRegEx = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;
  if (tbRegEx.test(ua))
    return 2
  if (smRegEx.test(ua))
    return 1
  else
    return 3
}